import React, { lazy, Suspense, memo, useContext } from 'react';

import pages from '../../constants/pages.json';
import { LoadingComponent } from '../../components/loading';
import { CreditcardContext } from '../../context/creditcard.context';
import { isTrue } from '../../utils/functions';

declare const window: any;

interface LayoutForRenderInterface {
  from: string;
  get: (key: string) => string;
  existsWindow: any;
  loading: boolean;
}

const LayoutForRender = memo(
  ({ from, get, existsWindow, loading, ...props }: LayoutForRenderInterface) => {
    if (!existsWindow) {
      return null;
    }

    if (loading) {
      return <LoadingComponent />;
    }

    const { UPDATE_USER_DETAIL } = pages.USER;
    const { CVV, CVU, UPDATE_CUSTOMER, ADDED_PAID, PAID, ACCOUNT_LOCK, GENERATE_PIN, RECOVER_PIN } = pages.SUCCESS_PAGE;

    const getComponent = (name: string) => (module: any) => ({
      default: module[`Success${name}Component`],
    });

    const layout = {
      [CVU]: () => import('../../components/success/cvu').then(getComponent('Cvu')),
      [CVV]: () => import('../../components/success/cvv').then(getComponent('Cvv')),
      [UPDATE_CUSTOMER]: () => import('../../components/success/customer').then(getComponent('Customer')),
      [UPDATE_USER_DETAIL]: () => import('../../components/success/user-detail').then(getComponent('UserDetail')),
      [ADDED_PAID]: () => import('../../components/success/added-paid').then(getComponent('AddedPaid')),
      [PAID]: () => import('../../components/success/paid').then(getComponent('Paid')),
      [ACCOUNT_LOCK]: () => import('../../components/success/account-lock').then(getComponent('AccountLock')),
      [GENERATE_PIN]: () => import('../../components/success/generate-pin').then(getComponent('GeneratePin')),
      [RECOVER_PIN]: () => import('../../components/success/recover-pin').then(getComponent('RecoverPin')),
      default: () => import('../../components/success/new-card').then(getComponent('NewCard')),
    };

    const Template = lazy(() => layout[from]());

    return (
      <Suspense fallback={null}>
        <Template get={get} {...props} />
      </Suspense>
    );
  },
  ({ from: prevFrom, loading: prevLoading }, { from: nextFrom, loading: nextLoading }) => {
    return [prevLoading === nextLoading, prevFrom === nextFrom].every(isTrue);
  }
);

const SuccessPage = ({ get, location }: PageProps) => {
  const windowGlobal = typeof window !== 'undefined' && window;
  const { loading } = useContext(CreditcardContext);
  const { state } = location;
  const { from = 'default', ...props } = state ?? windowGlobal?.history?.state ?? {};
  return <LayoutForRender existsWindow={windowGlobal} get={get} from={from} loading={loading} {...props} />;
};

export default SuccessPage;
